<template>
  <Toast />
  <ConfirmDialog />
  <div v-if="cargandoComponente">
    <div class="grid" v-if="permitirVenta">
      <div class="col-12 md:col-8">
        <div class="card">
          <div class="p-fluid formgrid grid p-fondo">
            <div class="field col-12 md:col-5 mt-2">
              <span v-if="this.tipo_negocio != 2"
                ><strong>BUSCAR CLIENTE | NOMBRE | CI/NIT:</strong></span
              >
              <span v-else><strong>BUSCAR POR PLACA | CLIENTE:</strong></span>
              <div class="p-inputgroup">
                <AutoComplete
                  field="nombre"
                  v-model="clienteSelect"
                  :suggestions="clientes"
                  :placeholder="
                    this.tipo_negocio == 2
                      ? 'Buscar por Placa | Cliente'
                      : 'Buscar Cliente | Nombre | CI/NIT'
                  "
                  v-tooltip.top="'Buscar Todos los Clientes'"
                  @keyup.enter="buscarCliente($event)"
                >
                  <template #item="slotProps">
                    <div v-if="this.tipo_negocio == 1">
                      <strong>{{ slotProps.item.nombre }}</strong>
                      <span>
                        <span><strong> - Nit:</strong></span>
                        {{ slotProps.item.ci_nit }}
                      </span>
                    </div>
                    <div v-else>
                      <strong v-tooltip.top="slotProps.item.nombre"
                        >{{ slotProps.item.nombre.substring(0, 10) }}...</strong
                      >

                      <span>
                        <Badge severity="warning" v-tooltip.top="'Placa'">{{
                          slotProps.item.placa
                        }}</Badge>
                        <Badge severity="info" v-tooltip.top="'Marca'">{{
                          slotProps.item.marca
                        }}</Badge>
                        <Badge severity="success" v-tooltip.top="'Modelo'">{{
                          slotProps.item.modelo
                        }}</Badge>
                      </span>
                    </div>
                  </template>
                </AutoComplete>
                <Button
                  icon="pi pi-search"
                  class="p-button-warning"
                  v-tooltip.top="'Buscar Cliente'"
                  @click="buscarCliente($event)"
                />
                <Button
                  v-if="
                    'Cliente Crear' in auth.user.permissions &&
                    this.tipo_negocio != 2
                  "
                  icon="pi pi-user-plus"
                  class="p-button-info"
                  v-tooltip.top="'Nuevo Cliente'"
                  @click="activarCliente"
                />

                <Button
                  v-if="
                    'Vehiculos Crear' in auth.user.permissions &&
                    this.tipo_negocio == 2 &&
                    this.clienteSelect != null
                  "
                  icon="pi pi-car"
                  class="p-button-info"
                  v-tooltip.top="'Nuevo Vehículo'"
                  @click="openModalVehiculo()"
                />
              </div>
              <div class="p-fluid formgrid mt-1 ml-2">
                <span
                  v-if="clienteSelect != null && clienteSelect.placa != null"
                  style="
                    font-size: 13px;
                    background-color: white;
                    border-radius: 2px;
                    border: 1px solid #ccc;
                    font-weight: bold;
                  "
                >
                  <span v-tooltip.top="'Placa'"
                    >{{ clienteSelect.placa }} -
                  </span>
                  <span v-tooltip.top="'Marca'"
                    >{{ clienteSelect.marca }} -
                  </span>
                  <span v-tooltip.top="'Modelo'"
                    >{{ clienteSelect.modelo }}
                  </span>
                </span>
              </div>
            </div>
            <div class="field col-12 md:col-3 mt-2">
              <span><strong>CI/NIT:</strong></span>
              <div class="p-inputgroup">
                <InputText
                  v-tooltip.top="'Nit a Facturar'"
                  v-model="nit"
                  placeholder="0"
                  :readonly="!facturar"
                  @keyup.enter="buscarClientePorNit"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                />
                <Button
                  v-if="'Cliente Crear' in auth.user.permissions"
                  icon="pi pi-search"
                  class="p-button-warning"
                  v-tooltip.top="'Buscar NIT'"
                  @click="buscarClientePorNit"
                  :disabled="!facturar"
                />
                <Button
                  v-if="facturar && !this.razon_social"
                  icon="pi pi-id-card"
                  class="p-button-info"
                  v-tooltip.top="'Registrar NIT'"
                  @click="activarClienteFactura"
                />
              </div>
            </div>
            <div class="field col-12 md:col-4 mt-2">
              <span><strong>RAZ&Oacute;N SOCIAL:</strong></span>
              <div class="p-inputgroup">
                <Textarea
                  v-model="razon_social"
                  rows="1"
                  autoResize
                  placeholder="Razón Social"
                  readonly
                ></Textarea>
              </div>
            </div>
          </div>
          <div class="p-fluid formgrid grid mt-2 mb-2">
            <div
              class="field col-12 md:col-5 mb-0"
              v-if="'Venta ConMedico' in auth.user.permissions"
            >
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                  <span v-if="this.tipo_negocio == 2"
                    ><strong>TRABAJADOR:</strong></span
                  >
                  <span v-else><strong>INTERM.:</strong></span>
                  <Checkbox
                    v-model="medico_referencia"
                    :binary="true"
                    class="ml-1 mr-1"
                    v-tooltip.top="'Trabajador/Intermediario'"
                  />
                </span>
                <Dropdown
                  v-if="medico_referencia == true"
                  v-model="medicoSelected"
                  :options="medicos"
                  placeholder="Seleccione..."
                  optionLabel="nombre_completo"
                  optionValue="id"
                  :filter="true"
                />
                <Button
                  v-if="
                    medico_referencia == true &&
                    'Medico Crear' in auth.user.permissions
                  "
                  icon="pi pi-id-card"
                  class="p-button-info"
                  v-tooltip.top="'Nuevo Trabajador/Intermediario'"
                  @click="activarMedico"
                />
              </div>
            </div>
            <div
              class="field col-12 md:col-4 mb-0"
              v-if="'Venta VenderPaquete' in auth.user.permissions"
            >
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                  <span v-if="vender_paquete != 'vender_paquete'"
                    ><strong>PAQUETE:</strong></span
                  >
                  <Checkbox
                    v-model="vender_paquete"
                    value="vender_paquete"
                    v-tooltip.top="'Vender Paquete'"
                  />
                </span>
                <Dropdown
                  v-if="vender_paquete == 'vender_paquete'"
                  v-model="paqueteSelected"
                  :options="paqueteproductos"
                  placeholder="Paquete..."
                  optionLabel="nombre"
                  optionValue="id"
                  :filter="true"
                />
                <Button
                  :disabled="!paqueteSelected"
                  v-if="vender_paquete == 'vender_paquete'"
                  icon="pi pi-plus"
                  class="p-button-info"
                  v-tooltip.top="'Agregar Paquete al Detalle'"
                  @click="agregarProductosPaqueteAlDetalle"
                />
              </div>
            </div>
            <div
              class="field col-12 md:col-3 mb-0"
              v-if="'Proforma Crear' in auth.user.permissions"
            >
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                  <span v-if="!buscar_proforma"
                    ><strong>PROFORMA:</strong></span
                  >
                  <Checkbox
                    v-model="buscar_proforma"
                    :binary="true"
                    v-tooltip.top="'Buscar Proforma'"
                  />
                </span>
                <InputText
                  v-if="buscar_proforma"
                  v-model="proforma_codigo"
                  placeholder="Código Proforma"
                  @keyup.enter="agregarProductosProforma"
                />
                <Button
                  :disabled="!proforma_codigo"
                  v-if="buscar_proforma"
                  icon="pi pi-search"
                  class="p-button-info"
                  v-tooltip.top="'Buscar Proforma'"
                  @click="agregarProductosProforma"
                />
              </div>
            </div>
          </div>
          <Panel
            toggleable
            :collapsed="true"
            class="shadow-2"
            @update:collapsed="actualizarPanelMasFiltros"
          >
            <template #header>
              <div class="p-inputgroup">
                <AutoComplete
                  field="descripcion"
                  placeholder="Buscar... Nombre de Producto"
                  v-tooltip.top="'Buscar Producto por Nombre'"
                  v-model="productoSelecionado"
                  :loading="true"
                  :delay="300"
                  :suggestions="productosListadoFiltrado"
                  @complete="buscarProducto($event)"
                >
                  <template #item="slotProps">
                    <div
                      class="flex align-options-center"
                      :class="{
                        'bg-gray-400':
                          (slotProps.item.stock.length > 0
                            ? sinDecimal(slotProps.item.stock[0].cantidad)
                            : 0) == 0,
                      }"
                    >
                      <div
                        class="text-overflow-ellipsis overflow-hidden p-0"
                        :class="
                          computedBuscarProductoPorSelected ? 'col-5' : 'col-7'
                        "
                        :title="slotProps.item.descripcion"
                      >
                        {{ slotProps.item.descripcion }}
                      </div>
                      <div
                        class="col-2 text-overflow-ellipsis overflow-hidden p-0"
                        :title="slotProps.item.fabrica_nombre"
                        style="font-weight: bold"
                      >
                        {{ slotProps.item.fabrica_nombre }}
                      </div>
                      <div
                        class="col-2 p-0 mr-1 text-overflow-ellipsis overflow-hidden"
                        style="background-color: aqua; border-radius: 2px"
                        v-if="
                          computedBuscarProductoPorSelected &&
                          slotProps.item.caso_uso != null &&
                          slotProps.item.caso_uso != ''
                        "
                        v-tooltip.top="slotProps.item.caso_uso"
                      >
                        {{ slotProps.item.caso_uso.substring(0, 15) }}...
                      </div>
                      <div
                        v-if="slotProps.item.tipo_catalogo == 1"
                        class="col-1 flex align-items-center justify-content-center p-0 mr-1"
                        :class="
                          stockClass(
                            slotProps.item.stock.length > 0
                              ? sinDecimal(slotProps.item.stock[0].cantidad)
                              : 0
                          )
                        "
                      >
                        <strong>{{
                          slotProps.item.stock.length > 0
                            ? stock_decimales
                              ? parseFloat(slotProps.item.stock[0].cantidad)
                              : sinDecimal(slotProps.item.stock[0].cantidad)
                            : 0
                        }}</strong>
                      </div>
                      <div
                        class="col-2 flex align-items-center justify-content-center p-0"
                        style="
                          font-size: 14px;
                          color: #ffffff;
                          background-color: #f44336;
                          border-radius: 2px;
                        "
                      >
                        <strong>
                          Bs
                          {{
                            slotProps.item.stock.length > 0
                              ? slotProps.item.stock[0].ultimo_precio
                              : "-"
                          }}</strong
                        >
                      </div>
                    </div>
                  </template>
                </AutoComplete>
                <Button
                  icon="pi pi-bars"
                  class="p-button-info"
                  v-tooltip.top="'VER RESULTADOS DE BUSQUEDA'"
                  :disabled="productosListadoFiltrado.length == 0"
                  @click="activarModalProductosFiltrados"
                ></Button>
              </div>
            </template>
            <div class="grid">
              <div class="col-12 md:col-4" v-show="false">
                <span><strong>C&Oacute;D BARRA:</strong></span>
                <AutoComplete
                  field="codigo_barra"
                  v-model="codProdSelected"
                  :suggestions="codProdListadoFiltrado"
                  v-tooltip.top="'Código'"
                  placeholder="Código..."
                  @item-select="buscarCodProducto"
                  @complete="buscarCodProducto"
                  @keyup.enter="buscarCodProducto"
                  autocomplete="off"
                />
              </div>
              <div class="p-inputgroup">
                <span class="mt-2 mr-2"><strong>GRUPO: </strong></span>
                <Dropdown
                  class="mr-2"
                  v-model="grupoSelected"
                  :options="grupos"
                  placeholder="Seleccionar Grupo..."
                  optionLabel="nombre"
                  optionValue="id"
                  :filter="true"
                />
                <span class="mt-2 mr-2"><strong>MARCAS: </strong></span>
                <Dropdown
                  class="mr-2"
                  v-model="laboratorioSelected"
                  :options="laboratorios"
                  placeholder="Seleccionar Marca..."
                  optionLabel="nombre"
                  optionValue="id"
                  :filter="true"
                />
                <Button
                  icon="pi pi-replay"
                  class="p-button-rounded p-button-info p-button-outlined ml-2"
                  v-tooltip.top="'Limpiar Filtros'"
                  @click="limpiarFiltrosBusquedaProducto"
                />
              </div>
              <div class="grid col-12 md:col-12 mt-2">
                <div
                  v-for="filtro of buscarProductoPorListado"
                  :key="filtro.value"
                  class="pr-1 mr-1 mt-1"
                >
                  <Checkbox
                    v-model="buscarProductoPorSelected"
                    :inputId="filtro.value"
                    name="filtro"
                    :value="filtro.value"
                  />
                  <span
                    class="ml-1"
                    style="font-size: 12px; font-weight: bold"
                    :for="filtro.value"
                    >{{ filtro.label }}</span
                  >
                </div>
              </div>
            </div>
          </Panel>
          <DataTable
            ref="dt_venta_detalle"
            class="p-datatable-sm mt-2"
            dataKey="id"
            :value="productosVentas"
            responsiveLayout="scroll"
            scrollHeight="400px"
            editMode="cell"
            @cell-edit-complete="guardandoCambiosCelda"
            :rowhover="true"
            stripedRows
            showGridlines
            selectionMode="single click"
          >
            <template #empty>
              <span
                class="flex align-items-center justify-content-center"
                style="
                  background-color: #f8d7da;
                  padding: 5px;
                  border-radius: 5px;
                  color: #721c24;
                "
                >Busca Productos para agregar al Detalle!</span
              ></template
            >
            <Column header="#">
              <template #body="slotProps">
                {{ 1 + slotProps.index }}
              </template>
            </Column>
            <Column field="nombre_producto" header="DESCRIPCIÓN/PRODUCTO">
              <template #body="{ data }">
                <span>
                  <strong>{{ data.nombre_producto }}</strong
                  ><strong> ** </strong>
                  <span v-if="data.nombre_fabrica != null">
                    {{ data.nombre_fabrica }}
                  </span>
                  <span v-else-if="data.fabrica_nombre != null">
                    {{ data.fabrica_nombre }}
                  </span>
                  <span v-else>{{
                    data.producto.fabrica_nombre != null
                      ? data.producto.fabrica_nombre
                      : ""
                  }}</span>
                </span>
              </template>
            </Column>
            <Column class="text-center strong" field="cantidad" header="STOCK">
              <template #body="{ data }">
                <span
                  v-if="data.tipo_catalogo === 1"
                  :class="claseEstado(data)"
                >
                  <strong> {{ parseFloat(data.cantidad) }}</strong>
                </span>
                <span v-else>-</span>
              </template>
            </Column>
            <Column
              v-if="stock_decimales"
              class="text-center strong bg-green-200"
              field="cantidad_venta"
              header="CANTIDAD"
              style="font-weight: bold; font-size: 14px"
            >
              <template #body="slotProps">
                {{ slotProps.data.cantidad_venta }}
              </template>
              <template #editor="{ data, field }">
                <InputNumber
                  v-model="data[field]"
                  :min="1"
                  locale="de-DE"
                  :minFractionDigits="2"
                  :maxFractionDigits="2"
                  mode="decimal"
                />
              </template>
            </Column>
            <Column
              v-else
              class="text-right strong bg-green-200"
              field="cantidad_venta"
              header="CANTIDAD"
            >
              <template #body="slotProps">
                {{ slotProps.data.cantidad_venta }}
              </template>
              <template #editor="{ data, field }">
                <InputNumber v-model="data[field]" :min="1" locale="de-DE" />
              </template>
            </Column>
            <Column
              class="text-right strong"
              field="ultimo_precio"
              header="PRECIO"
            >
              <template #body="slotProps">
                {{
                  convertirNumeroGermanicFormat(slotProps.data.ultimo_precio)
                }}
              </template>
              <template
                #editor="{ data, field }"
                v-if="'Venta EditarPrecio' in this.auth.user.permissions"
              >
                <InputNumber
                  v-model="data[field]"
                  :inputClass="'text-right'"
                  :min="0"
                  :minFractionDigits="2"
                  :maxFractionDigits="2"
                  locale="de-DE"
                />
              </template>
            </Column>
            <Column
              v-if="aplica_descuento == 'aplica_descuento'"
              class="flex-row-reverse strong bg-green-200"
              field="descuento_neto"
              header="DESC."
            >
              <template #body="slotProps">
                {{
                  convertirNumeroGermanicFormat(slotProps.data.descuento_neto)
                }}
              </template>
              <template #editor="slotProps">
                <InputNumber
                  :inputClass="'text-right'"
                  :min="0"
                  :minFractionDigits="2"
                  :maxFractionDigits="2"
                  locale="de-DE"
                  v-model="slotProps.data.descuento_neto"
                />
              </template>
            </Column>
            <Column class="text-right" field="total" header="TOTAL">
              <template #body="slotProps">
                {{
                  slotProps.data.ultimo_precio > 0
                    ? convertirNumeroGermanicFormat(
                        slotProps.data.ultimo_precio *
                          slotProps.data.cantidad_venta -
                          slotProps.data.descuento_neto
                      )
                    : convertirNumeroGermanicFormat(0)
                }}
              </template>
            </Column>
            <!--  <Column v-show="false">
              <template #header>
                <i class="pi pi-check"></i>
              </template>
              <template #body="slotProps">
                <Checkbox
                  v-model="slotProps.data.entregado"
                  value="entregado"
                  :disabled="slotProps.data.cantidad_venta == 0"
                  v-tooltip.top="'Entregado'"
                />
              </template>
            </Column> -->
            <Column class="strong">
              <template #header>
                <i class="pi pi-trash"></i>
              </template>
              <template #body="slotProps">
                <Button
                  @click="quitarProductoDetalle(slotProps)"
                  v-tooltip.top="'Quitar Producto'"
                  icon="pi pi-times"
                  class="p-button-rounded p-button-danger p-button-text"
                />
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
      <div class="col-12 md:col-4">
        <div class="card">
          <h4><strong>TOTALES DE VENTAS:</strong></h4>
          <div class="p-fluid formgrid grid">
            <table class="field col-12 md:col-12">
              <tr>
                <td>
                  <strong>SUB TOTAL:</strong>
                </td>
                <td class="text-right">
                  {{ convertirNumeroGermanicFormat(subtotal) }} Bs.
                </td>
              </tr>
              <tr v-if="aplica_descuento == 'aplica_descuento'">
                <td>
                  <strong>DESCUENTO:</strong>
                </td>
                <td class="text-right">
                  {{ convertirNumeroGermanicFormat(descuento) }} Bs.
                </td>
              </tr>
              <tr v-if="venta_credito">
                <td>
                  <strong>Interes:</strong>
                </td>
                <td class="text-right">
                  {{
                    convertirNumeroGermanicFormat(monto_venta_a_credito_interes)
                  }}
                  Bs.
                </td>
              </tr>
              <tr>
                <td>
                  <h4><strong>TOTAL VENTA:</strong></h4>
                </td>
                <td v-if="!venta_credito" class="text-right">
                  <h2>{{ convertirNumeroGermanicFormat(total_venta) }} Bs.</h2>
                </td>
                <td v-else>
                  <h2>
                    {{
                      convertirNumeroGermanicFormat(
                        total_venta + monto_venta_a_credito_interes
                      )
                    }}
                    Bs.
                  </h2>
                </td>
              </tr>
              <tr v-if="tipo_pagoSelect == 2">
                <td>
                  <strong>A CUENTA:</strong>
                </td>
                <td class="text-right">
                  {{ convertirNumeroGermanicFormat(a_cuenta + monto_pago) }} Bs.
                </td>
              </tr>
              <tr v-if="tipo_pagoSelect == 2">
                <td>
                  <strong>SALDO:</strong>
                </td>
                <td v-if="!venta_credito" class="text-right">
                  {{
                    convertirNumeroGermanicFormat(
                      total_venta - a_cuenta - monto_pago
                    )
                  }}
                  Bs.
                </td>
                <td v-else class="text-right">
                  {{
                    convertirNumeroGermanicFormat(
                      total_venta -
                        a_cuenta -
                        monto_pago +
                        monto_venta_a_credito_interes
                    )
                  }}
                  Bs.
                </td>
              </tr>
            </table>
            <div class="field col-12 md:col-12" v-if="facturar" v-show="false">
              <span><strong>ACTIVIDAD ECONOMICA:</strong></span>
              <div class="p-inputgroup">
                <Dropdown
                  id="actividad_economica"
                  v-model="actividad_economica_selected"
                  :options="actividades_economicas"
                  option-label="descripcion"
                  option-value="codigo"
                  placeholder="Seleccione una actividad economica"
                  @change="cambiarActividadEconomica"
                />
              </div>
            </div>
            <div class="p-field field col-12 md:col-12">
              <div class="p-inputgroup">
                <template v-if="'Venta Facturar' in auth.user.permissions">
                  <Checkbox
                    class="mr-1"
                    value="facturar"
                    v-model="facturar"
                    :binary="true"
                    v-tooltip.top="'Facturar'"
                    :disabled="
                      'Venta Facturar' in auth.user.permissions == false ||
                      total_venta == 0
                    "
                  /><span class="p-tag p-tag-rounded p-tag-danger mr-3"
                    >ENVIAR</span
                  >
                </template>
                <template v-if="'Venta FactIndicador' in auth.user.permissions">
                  <Checkbox
                    class="mr-1"
                    v-model="fact_indicador"
                    :binary="true"
                    v-tooltip.top="'Solo marcar como facturado'"
                    :disabled="total_venta == 0"
                  /><span class="p-tag p-tag-rounded p-tag-danger mr-3"
                    >FACTURADO</span
                  >
                </template>
                <Checkbox
                  :class="
                    'Venta Facturar' in auth.user.permissions == false
                      ? 'mr-1'
                      : 'ml-3 mr-1'
                  "
                  value="aplica_descuento"
                  v-model="aplica_descuento"
                  v-tooltip.top="'Aplicar Descuento'"
                  :disabled="
                    'Venta Descuentos' in auth.user.permissions == false ||
                    descuento > 0 ||
                    total_venta == 0
                  "
                />
                <span class="p-tag p-tag-rounded p-tag-warning">DESCUENTO</span>
                <Checkbox
                  class="mr-1 ml-2"
                  value="observacion_check"
                  id="binary"
                  v-tooltip.top="'Observación'"
                  v-model="observacion_check"
                />
                <span class="p-tag p-tag-rounded p-tag-info">OBSERVACIÓN</span>
              </div>
            </div>
            <div
              class="field col-12 md:col-12"
              v-if="observacion_check == 'observacion_check'"
            >
              <Textarea
                v-model="observacion"
                rows="3"
                autoResize
                placeholder="Glosa/observación"
              />
            </div>
            <template v-if="aplica_descuento == 'aplica_descuento'">
              <table class="field col-12 md:col-12">
                <tr
                  v-if="
                    grupos_id_selected != '' &&
                    grupos_id_selected != null &&
                    aplica_descuento == 'aplica_descuento'
                  "
                >
                  <td>
                    <strong>DESC. ASIGNADOS:</strong>
                  </td>
                  <td
                    class="text-right"
                    style="
                      background-color: #fbc02d;
                      border-radius: 5px;
                      padding: 1px;
                      font-weight: bold;
                    "
                  >
                    {{ grupos_id_selected }}
                  </td>
                </tr>
                <tr v-show="true">
                  <td>
                    <strong>DESC. NETO :</strong>
                  </td>
                  <td class="text-right">
                    <InputNumber
                      v-model="descuento"
                      locale="de-DE"
                      input-class="text-right"
                      :min="0"
                      :max="subtotal"
                      :minFractionDigits="2"
                      :maxFractionDigits="2"
                      placeholder="descuento neto"
                      @keyup="calcular_agregar_des_total_venta"
                    />
                  </td>
                  <td class="text-right">
                    <Button
                      icon="pi pi-check"
                      class="p-button-outlined p-button-secondary"
                      v-tooltip.top="'Descuento neto'"
                      @click="calcular_agregar_des_total_venta"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>DESCUENTO %:</strong>
                  </td>
                  <td class="text-right strong">
                    <InputNumber
                      v-model="desc_venta_porcentaje"
                      locale="de-DE"
                      input-class="text-right"
                      :min="0"
                      :max="100"
                      :minFractionDigits="2"
                      :maxFractionDigits="2"
                      placeholder="descuento %"
                      @keyup="calcular_agregar_des_total_venta_porcentaje"
                    />
                  </td>
                  <td class="text-right">
                    <Button
                      v-tooltip.top="'Descuento %'"
                      icon="pi pi-check"
                      class="p-button-outlined p-button-secondary"
                      @click="calcular_agregar_des_total_venta_porcentaje"
                    />
                  </td>
                </tr>
              </table>
            </template>
            <div class="p-field field col-12 md:col-12">
              <div class="p-inputgroup">
                <template v-if="'Venta Credito' in auth.user.permissions">
                  <Checkbox
                    class="mr-1"
                    v-model="venta_credito"
                    :binary="true"
                    v-tooltip.top="'Venta a Crédito'"
                  ></Checkbox>
                  <span
                    :class="
                      'p-tag p-tag-rounded' +
                      (total_venta == 0 ? ' bg-black-alpha-20' : ' p-tag-info')
                    "
                    >A CRÉDITO</span
                  >
                </template>
                <template v-if="'Venta M.Pagos' in auth.user.permissions">
                  <Checkbox
                    :class="
                      'Venta Credito' in auth.user.permissions == false
                        ? 'mr-1'
                        : 'ml-3 mr-1'
                    "
                    value="metodo_pago_check"
                    v-model="metodo_pago_check"
                    v-tooltip.top="'Seleccionar Método de Pago'"
                  />
                  <span
                    :class="
                      'p-tag p-tag-rounded' +
                      (total_venta == 0
                        ? ' bg-black-alpha-20'
                        : ' p-tag-success')
                    "
                    >PAGO QR</span
                  >
                </template>
                <template v-if="'Proforma Crear' in auth.user.permissions">
                  <Checkbox
                    class="ml-3 mr-1"
                    value="proforma"
                    :binary="true"
                    v-tooltip.top="'Crear como proforma'"
                    v-model="proforma"
                    :disabled="total_venta == 0"
                  />
                  <span
                    :class="
                      'p-tag p-tag-rounded' +
                      (total_venta == 0
                        ? ' bg-black-alpha-20'
                        : ' p-tag-danger')
                    "
                    >PROFORMA</span
                  >
                </template>
              </div>
            </div>
            <div class="field col-12 md:col-12">
              <table>
                <tr v-if="a_credito_check == 'a_credito_check'">
                  <td>
                    <strong>TIPO DE PAGO: </strong>
                  </td>
                  <td>
                    <Dropdown
                      id="tipo_pago"
                      v-model="tipo_pagoSelect"
                      :options="tipo_pagos"
                      optionLabel="nombre"
                      optionValue="id"
                    />
                  </td>
                </tr>
                <tr v-if="tipo_pagoSelect == 2">
                  <td>
                    <strong>D&Iacute;AS CR&Eacute;DITO: </strong>
                  </td>
                  <td>
                    <InputNumber
                      v-model="dias_credito"
                      :min="1"
                      :inputClass="'text-right'"
                      locale="de-ED"
                      :useGrouping="false"
                      showButtons
                    />
                  </td>
                </tr>
                <tr v-if="venta_credito">
                  <td>
                    <strong>INTERES %: </strong>
                  </td>
                  <td>
                    <InputNumber
                      v-model="interes_venta_a_credito"
                      :min="0"
                      :inputClass="'text-right'"
                      locale="de-ED"
                      :minFractionDigits="2"
                      :useGrouping="false"
                      showButtons
                      placeholder="porcentaje interes"
                    />
                  </td>
                </tr>
                <tr v-if="venta_credito">
                  <td>
                    <strong>A CUENTA EFECTIVO: </strong>
                  </td>
                  <td>
                    <InputNumber
                      v-model="a_cuenta"
                      :min="0"
                      :max="total_venta - monto_pago - 1"
                      :inputClass="'text-right'"
                      locale="de-ED"
                      :minFractionDigits="2"
                      :useGrouping="false"
                      showButtons
                      placeholder="a cuenta efectivo"
                    />
                  </td>
                </tr>
                <tr v-if="metodo_pago_check == 'metodo_pago_check'">
                  <td>
                    <strong>M&Eacute;TODO PAGO: </strong>
                  </td>
                  <td>
                    <Dropdown
                      id="metodo_pago"
                      v-model="metodo_pagoSelect"
                      :options="metodo_pagos"
                      optionLabel="nombre"
                      optionValue="id"
                    />
                  </td>
                </tr>
                <tr v-if="metodo_pagoSelect == 6 && qr_cobros != null">
                  <td>
                    <strong>QR COBROS: </strong>
                  </td>
                  <td>
                    <Image
                      :src="'data:image/png;base64, ' + qr_cobros"
                      width="150"
                      preview
                    ></Image>
                  </td>
                </tr>
                <tr v-if="metodo_pagoSelect == 2 && facturar == 1">
                  <td>
                    <strong>N&Uacute;MERO DE TARJETA: </strong>
                  </td>
                  <td>
                    <InputNumber
                      v-model="nro_tarjeta"
                      :min="8"
                      :inputClass="'text-right'"
                      locale="de-ED"
                      :useGrouping="false"
                      placeholder="Ingrese el número de tarjeta"
                    />
                  </td>
                </tr>
                <tr v-if="metodo_pagoSelect != 1">
                  <td>
                    <strong>MONTO PAGO: </strong>
                  </td>
                  <td>
                    <InputNumber
                      v-model="monto_pago"
                      :min="0"
                      :max="total_venta - a_cuenta"
                      :inputClass="'text-right'"
                      locale="de-ED"
                      :useGrouping="false"
                      :minFractionDigits="2"
                      showButtons
                      placeholder="monto pago transferencias"
                    />
                  </td>
                </tr>
              </table>
            </div>
            <div class="field col-12 md:col-6 hidden">
              <span><strong>Cotizaci&oacute;n</strong></span>
              <Checkbox class="ml-3" value="cotizar" v-model="cotizar" />
            </div>
            <table class="field col-12 md:col-12">
              <tr v-if="!venta_credito">
                <td>
                  <strong>RECIBIDO:</strong>
                </td>
                <td class="text-right">
                  <InputNumber
                    v-model="efectivo"
                    :min="0"
                    locale="de-DE"
                    input-class="text-right"
                    :minFractionDigits="2"
                    inputId="locale-german"
                    :disabled="
                      metodo_pagoSelect != 1
                        ? true
                        : false || a_credito_check == 'a_credito_check'
                    "
                  />
                </td>
              </tr>
              <tr v-if="venta_credito">
                <td>
                  <strong>SALDO DEBE:</strong>
                </td>
                <td class="text-right">
                  <InputNumber
                    v-model="venta_credito_saldo_debe"
                    :min="0"
                    locale="de-DE"
                    input-class="text-right"
                    :minFractionDigits="2"
                    inputId="locale-german"
                  />
                </td>
              </tr>
              <tr v-if="venta_credito">
                <td>
                  <strong> MONTO INTERES: </strong>
                </td>
                <td class="text-right">
                  <InputNumber
                    v-model="monto_venta_a_credito_interes"
                    :min="0"
                    locale="de-DE"
                    input-class="text-right"
                    :minFractionDigits="2"
                    inputId="locale-german"
                  />
                </td>
              </tr>

              <tr v-if="!venta_credito">
                <td>
                  <strong>
                    <h2>CAMBIO:</h2>
                  </strong>
                </td>
                <td class="text-right p-invalid">
                  <strong>
                    <h1>{{ convertirNumeroGermanicFormat(cambio) }} Bs.</h1>
                  </strong>
                </td>
              </tr>
            </table>
            <div class="col-12 md:col-12 flex justify-content-end">
              <Button
                :label="
                  proforma == 1
                    ? 'REGISTRAR PROFORMA'
                    : facturar == 1
                    ? 'REGISTRAR FACTURA'
                    : 'REGISTRAR VENTA'
                "
                icon="pi pi-save"
                v-tooltip.top="
                  proforma == 1
                    ? 'Registrar Proforma'
                    : facturar == 1
                    ? 'Registrar Factura'
                    : 'Registrar Venta'
                "
                class="p-button-info p-button-lg"
                @click="guardarVenta"
                :disabled="productosVentas.length == 0"
                :loading="guardando"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid" v-else>
      <Message class="col-12" :severity="'info'" :closable="false">
        <div class="flex align-items-center justify-content-center">
          <span> PARA REALIZAR VENTAS DEBE ABRIR LA CAJA </span>
          <Button
            label="APERTURAR CAJA"
            class="p-button-success p-button-lg ml-3"
            icon="pi pi-lock-open"
            v-tooltip.top="'Abrir Caja'"
            @click="this.$router.push({ name: 'caja' })"
          />
        </div>
      </Message>
    </div>
  </div>
  <div v-else>
    <div class="grid">
      <h3 class="col-12">Verificando caja ...</h3>
      <ProgressSpinner
        style="width: 50px; height: 50px"
        strokeWidth="8"
        fill="var(--surface-ground)"
        animationDuration=".5s"
        class="col-12"
      />
    </div>
  </div>
  <ClienteCreate
    :show="clienteModal"
    :cliente="cliente"
    @closeModal="cerrarModalCliente"
    @actualizarListado="cargarClientesListado"
  >
  </ClienteCreate>
  <ClienteFactura
    :show="clienteFacturaModal"
    :cliente_factura="cliente_factura"
    @closeModal="cerrarModalClienteFactura"
    @actualizarListado="buscarClientePorNit"
  >
  </ClienteFactura>
  <MedicoCreate
    :show="medicoModal"
    :medico="medico"
    @closeModal="cerraModalMedico"
    @actualizarListado="cargarMedicosListado"
  >
  </MedicoCreate>

  <ProducoListadoModal
    @closeModal="cerrarModalProductoResultados"
    :show="productosResultados"
    :productos="productosFiltrados"
    @agregarProductos="agregarProductos"
  ></ProducoListadoModal>
  <VehiculoCreate
    :show="vehiculoModal"
    :vehiculo="vehiculo"
    @closeModalVehiculo="cerrarModalVehiculo"
    @actualizarListadoVehiculo="cargarVehiculos"
  >
  </VehiculoCreate>
</template>

<script>
import ClienteService from "@/service/ClienteService";
import ProductService from "@/service/ProductService";
import VentaService from "@/service/VentaService";
import ClienteCreate from "@/module/clientes/ClienteCreate.vue";
import ClienteFactura from "@/module/clientes_facturas/ClienteFactura.vue";
import MedicoCreate from "@/module/medicos/MedicoCreate.vue";
import CajaService from "@/service/CajaService";
import { useAuth } from "@/stores";
import { has } from "lodash";
import TipoPagoService from "@/service/TipoPagoService";
import MetodoPagoService from "@/service/MetodoPagoService";
import PaqueteProductoService from "@/service/PaqueteProductoService";
import FacturaService from "@/service/FacturaService";
import MedicoService from "@/service/MedicoService";
import ParametrosSistemaService from "@/service/ParametrosSistemaService";
import ProducoListadoModal from "@/module/productos/ProductoListadoModal.vue";
import EmpresaService from "@/service/EmpresaService";
import VehiculoCreate from "@/module/vehiculos/VehiculoCreate.vue";
import VehiculoService from "@/service/VehiculoService";

export default {
  components: {
    ClienteCreate,
    ClienteFactura,
    ProducoListadoModal,
    MedicoCreate,
    VehiculoCreate,
  },
  data() {
    return {
      masFiltrosBusqueda: false,
      buscarProductoPorSelected: ["nombre_comercial"],
      buscarProductoPorListado: [
        { label: "NOMBRE", value: "nombre_comercial" },
        { label: "CÓD INTERNO", value: "codigo_interno" },
        { label: "CÓD EQUIV.", value: "codigo_equivalente" },
        { label: "CÓD BARRA", value: "codigo_barra" },
        { label: "CÓD ORIGEN", value: "codigo_origen" },
        { label: "MEDIDAS", value: "medidas" },
      ],
      grupoSelected: null,
      laboratorioSelected: null,
      grupos: [],
      laboratorios: [],
      venta_credito_saldo_debe: 0,
      proforma: false,
      permitir_facturar: false,
      facturar: false,
      cotizar: false,
      guardando: false,
      clienteSelect: null,
      clientes: [],
      razon_social: null,
      nit: 0,
      productoSelecionado: null,
      productosSelecionado: null,
      productosListadoFiltrado: [],
      productosVentas: [],
      stockSucursales: [],
      subtotal: 0,
      descuento: 0,
      total_venta: 0,
      efectivo: 0,
      monto_pago: 0, //monto de pago en efectivo
      clienteModal: false,
      clienteFacturaModal: false,
      cliente: { estado: { label: "Activo", value: 1 } },
      clienteFactura: { estado: { label: "Activo", value: 1 } },
      medicoModal: false,
      medico: { estado: { label: "Activo", value: 1 } },
      fecha_venta: null,
      permitirVenta: false,
      cargandoComponente: false,
      sucursal_id: null,
      caja: null,
      codProdSelected: null,
      codProdListadoFiltrado: [],
      desc_venta_porcentaje: null,
      aplica_descuento: false,
      tipo_pagos: [],
      tipo_pagoSelect: 1,
      metodo_pagos: [],
      metodo_pagoSelect: 1,
      dias_credito: 0,
      a_cuenta: 0,
      venta_credito: false,
      saldo: 0,
      observacion: "",
      observacion_check: false,
      a_credito_check: false,
      metodo_pago_check: false,
      stockClass: (data) => {
        if (data <= 0) {
          return "outofstock";
        } else if (data <= 10) {
          return "lowstock";
        } else {
          return "instock";
        }
      },
      claseEstado: (data) => {
        if (data.cantidad <= 0) {
          return "outofstock";
        } else if (data.cantidad <= 10) {
          return "lowstock";
        } else {
          return "instock";
        }
      },
      vender_paquete: false,
      paqueteSelected: null,
      paqueteproductos: [],
      paqueteproductosListado: [],
      entregado: 0,
      medico_referencia: false,
      medicos: [],
      medicoSelected: null,
      productosResultados: false,
      productosFiltrados: [],
      actividades_economicas: [],
      actividad_economica_selected: null,
      nro_tarjeta: null,
      filtrosRadio: "precio_venta",
      cliente_factura: {},
      monto_venta_a_credito_interes: 0,
      interes_venta_a_credito: 0,
      grupos_id_selected: "",
      qr_cobros: null,
      vehiculoModal: false,
      vehiculo: {},
      tipo_negocio: 1,
      fact_indicador: false,
      buscar_proforma: false,
      proforma_codigo: null,
      proforma_id: null,
      parametros_sistema: {},
      stock_decimales: false,
    };
  },
  clienteService: null,
  productService: null,
  ventaService: null,
  cajaService: null,
  auth: null,
  tipoPagoService: null,
  metodoPagoService: null,
  paqueteProductoService: null,
  medicoService: null,
  facturaService: null,
  parametrosSistemaService: null,
  empresaService: null,
  vehiculoService: null,
  created() {
    this.auth = useAuth();
    this.clienteService = new ClienteService();
    this.productService = new ProductService();
    this.ventaService = new VentaService();
    this.cajaService = new CajaService();
    this.tipoPagoService = new TipoPagoService();
    this.metodoPagoService = new MetodoPagoService();
    this.paqueteProductoService = new PaqueteProductoService();
    this.medicoService = new MedicoService();
    this.facturaService = new FacturaService();
    this.parametrosSistemaService = new ParametrosSistemaService();
    this.empresaService = new EmpresaService();
    this.vehiculoService = new VehiculoService();
  },
  mounted() {
    this.datos_venta_general();
    /* this.verificarCajaAbierta(); */
    /* this.cargarClientesListado(); */
    /* this.obtenerTiposPagos(); */
    /* this.obtenerMetodosPagos(); */
    /* this.obtenerPaquetes(); */
    /* this.obtenerMedicos(); */
    /* this.verificarFacturar(); */
  },
  computed: {
    // si mi venta es al credito entonces en efectivo se registra el monto  a cuenta el cambio es el total efectivo - a cuenta
    cambio() {
      if (this.tipo_pagoSelect == 2) {
        return this.efectivo - this.a_cuenta;
      } else {
        return this.efectivo + this.monto_pago - this.total_venta;
      }
    },
    computedBuscarProductoPorSelected() {
      return this.buscarProductoPorSelected.includes("principio_activo");
    },
  },
  methods: {
    openModalVehiculo() {
      this.vehiculo = {
        estado: { label: "Activo", value: 1 },
        placa: this.clienteSelect.nombre ?? this.clienteSelect,
        cliente_id: 1,
      };
      this.vehiculoModal = true;
    },
    cerrarModalVehiculo() {
      this.vehiculo = {};
      this.vehiculoModal = false;
      //this.$emit("closeModalVehiculo");
    },
    actualizarPanelMasFiltros(e) {
      this.masFiltrosBusqueda = e;
      if (!this.masFiltrosBusqueda) {
        this.limpiarFiltrosBusquedaProducto();
      }
    },
    limpiarFiltrosBusquedaProducto() {
      this.buscarProductoPorSelected = ["nombre_comercial"];
      this.grupoSelected = null;
      this.laboratorioSelected = null;
    },
    cambiarActividadEconomica() {
      let datos = {
        actividad_economica_id: this.actividad_economica_selected,
      };
      this.empresaService.changeActividad(datos).then((data) => {
        if (data.status == 200) {
          this.$toast.add({
            severity: "success",
            summary: "ACTIVIDAD ECONOMICA",
            detail: data.message,
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Error al cambiar la actividad economica",
            life: 3000,
          });
        }
      });
    },
    calcular_agregar_des_total_venta() {
      this.calcular_descuento_venta_porcentaje();
      this.agregar_descuento_detalle_productosventas();
      this.calcular_total_venta();
    },
    calcular_agregar_des_total_venta_porcentaje() {
      this.calcular_descuento_venta_neto();
      this.agregar_descuento_detalle_productosventas();
      this.calcular_total_venta();
    },
    cerrarModalProductoResultados() {
      this.productosResultados = false;
    },
    activarModalProductosFiltrados() {
      this.productosResultados = true;
      this.productosFiltrados = this.productosListadoFiltrado;
    },
    datos_venta_general() {
      let datos = {
        verificar_caja: true,
        facturar: "facturar",
      };
      this.ventaService.VentaGeneral(datos).then((response) => {
        if (response.success) {
          if (response.datos.caja.length == 0) {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: "No se encontró una caja abierta",
            });
            this.permitirVenta = false;
            this.cargandoComponente = true;

            return;
          }

          this.caja = response.datos.caja[0];
          this.sucursal_id = response.datos.caja[0].sucursal_id;
          this.qr_cobros = response.datos.qr_cobros;
          this.permitirVenta = true;

          this.tipo_negocio = response.datos.tipo_negocio ?? 1;
          if (this.tipo_negocio != 2) {
            this.clientes = response.datos.clientes;
            this.clienteSelect = this.clientes.length ? this.clientes[0] : null;
          }
          this.tipo_pagos = response.datos.tipo_pagos;
          this.metodo_pagos = response.datos.metodo_pagos;
          this.paqueteproductos = response.datos.paquetes;
          this.medicos = response.datos.medicos;
          this.grupos = response.datos.grupos;
          this.laboratorios = response.datos.laboratorios;

          if (response.datos.facturar) {
            this.permitir_facturar = true;
          } else {
            this.permitir_facturar = false;
          }
          this.actividades_economicas =
            response.datos.actividades_economicas || [];
          if (this.actividades_economicas.length) {
            this.actividad_economica_selected =
              response.datos.actividad_economica_select.codigo;
          }
          this.parametros_sistema = response.datos.parametros_sistema ?? {};
          this.parametros_sistema.some((parametro) => {
            if (parametro.nombre == "stock_decimales") {
              this.stock_decimales = true;
            }
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "No se encontró una caja abierta",
            life: 60000,
          });
        }

        this.cargandoComponente = true;
      });
    },
    verificarFacturar() {
      this.parametrosSistemaService
        .verificarPermiso("facturar")
        .then((response) => {
          if (response.permiso) {
            this.permitir_facturar = true;
          } else {
            this.permitir_facturar = false;
          }
        });
    },
    obtenerMedicos() {
      this.medicoService.getMedicosAll().then((response) => {
        this.medicos = response.medicos;
      });
    },
    obtenerPaquetes() {
      this.paqueteProductoService.getPaqueteProductoAll().then((response) => {
        this.paqueteproductos = response.paqueteproductos;
      });
    },
    obtenerTiposPagos() {
      this.tipoPagoService.getAllTipoPagos().then((response) => {
        this.tipo_pagos = response.tipo_pagos;
      });
    },
    obtenerMetodosPagos() {
      this.metodoPagoService.getAllMetodoPagos().then((response) => {
        this.metodo_pagos = response.metodo_pagos;
      });
    },
    buscarClientePorNit() {
      //BUSCO EL CLIENTE POR NIT Y SI NO EXISTE LO CREO
      if (this.nit != null) {
        this.clienteService
          .buscarClientePorNit({ nit: this.nit })
          .then((response) => {
            if (response.cliente != null) {
              this.$toast.add({
                severity: "success",
                summary: "Nit encontrado",
                detail: "Se cargaron los datos del cliente",
                life: 3000,
              });
              /* this.razon_social = response.cliente.nombre_cliente; */
              let nombre_cliente =
                response.cliente.nombre_cliente ?? response.cliente;
              this.razon_social = nombre_cliente ?? "";
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Cliente no encontrado",
                detail: "Registre los datos del cliente",
                life: 3000,
              });
              /* this.cliente.razon_social = this.razon_social;
              this.cliente.nit = this.nit;
              this.cliente_id = this.clienteSelect.id; */
              this.razon_social = "";
              this.activarClienteFactura();
            }
          });
      }
    },

    aplicar_descuento_productos_detalle() {
      this.productosVentas.forEach((producto) => {
        producto.descuento_porcentaje = this.desc_venta_porcentaje;
        producto.descuento_neto =
          producto.ultimo_precio *
          producto.cantidad_venta *
          (producto.descuento_porcentaje / 100);
      });
      this.calcular_subtotal();
    },
    buscarCliente() {
      let datos = {
        texto: this.clienteSelect ?? null,
        sucursal_id: this.sucursal_id,
      };
      if (this.clienteSelect != null) {
        this.clienteService.buscarXNombre(datos).then((response) => {
          if (response.clientes.length == 0) {
            this.$toast.add({
              severity: "error",
              summary: "ERROR",
              detail: "No se encontró el cliente",
              life: 3000,
            });
          }
          this.clientes = [...response.clientes];
        });
      }
    },
    /* buscarCliente(event) {
      setTimeout(() => {
        this.clienteService
          .buscarXNombre({ texto: event.query, sucursal_id: this.sucursal_id })
          .then((response) => {
            if (response.clientes.length > 0) {
              this.clientes = [...response.clientes];
            }
          });
      }, 250);
    }, */
    verificarCajaAbierta() {
      this.cajaService.verificarCajaAbierta().then((response) => {
        if (response.caja.length > 0) {
          this.caja = response.caja[0];
          this.sucursal_id = response.caja[0].sucursal_id;
          this.permitirVenta = true;
        } else {
          this.permitirVenta = false;
        }

        this.cargandoComponente = true;
      });
    },

    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(numero);
    },
    sinDecimal(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(numero);
    },

    verificarMedico() {
      if (this.medico_referencia == true && this.medicoSelected == null) {
        this.$toast.add({
          severity: "error",
          summary: "Trabajador",
          detail: "Debe seleccionar un Trabajador",
          life: 60000,
        });
        this.guardando = false;
        return;
      }

      return true;
    },

    guardarVenta() {
      if (!this.venta_credito && this.cambio < 0) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "El cambio debe ser mayor a 0",
          life: 60000,
        });
        return;
      }
      if (this.metodo_pagoSelect == 2 && this.facturar == true) {
        if (
          this.nro_tarjeta == null ||
          this.nro_tarjeta.toString().length < 8
        ) {
          this.$toast.add({
            severity: "error",
            summary: "Numero de tarjeta",
            detail: "El numero de tarjeta debe tener al menos 8 digitos",
            life: 60000,
          });
          this.guardando = false;
          return;
        }
      }
      let productos = this.productosVentas.filter(
        (producto) =>
          producto.cantidad_venta == 0 || producto.cantidad_venta == null
      );
      if (productos.length > 0) {
        this.$toast.add({
          severity: "error",
          summary: "Revisar productos con cantidad de venta en 0",
          detail:
            "No puede guardar una venta con productos con cantidad de venta en 0",
          life: 60000,
        });
        this.guardando = false;
        return;
      }
      /* <empty string> */
      if (this.clienteSelect == "" || this.clienteSelect == null) {
        this.$toast.add({
          severity: "error",
          summary: "CLIENTE",
          detail: "DEBE SELECCIONAR UN CLIENTE",
          life: 10000,
        });
        this.guardando = false;
        return;
      }
      if (!this.verificarMedico()) {
        this.guardando = false;
        return;
      }

      let cliente_id =
        this.clienteSelect.cliente_id != null
          ? this.clienteSelect.cliente_id // Obtener el id del cliente de vehiculos
          : this.clienteSelect.id; // Obtener el id del cliente de clientes

      let datos = {
        razon_social: this.razon_social,
        nit: this.nit,
        cliente_id: cliente_id,
        total_cancelar: this.total_venta,
        descuento: this.descuento,
        efectivo: this.efectivo,
        productos: this.productosVentas,
        caja_id: this.caja.id,
        tipo_pago_id: this.tipo_pagoSelect,
        metodo_pago_id: this.metodo_pagoSelect,
        monto_pago: this.monto_pago,
        dias_credito: this.dias_credito,
        a_cuenta: this.a_cuenta,
        cambio: this.cambio,
        facturado: this.facturar ? 1 : 0,
        observacion: this.observacion,
        nombre_paquete: this.paqueteSelected ? this.paqueteSelected : null,
        nro_tarjeta: this.nro_tarjeta ? this.nro_tarjeta : null,
        proforma: this.proforma,
        interes_venta_credito: this.interes_venta_a_credito,
        monto_interes_venta_credito: this.monto_venta_a_credito_interes,
        venta_credito: this.venta_credito,
        vehiculo_id: this.clienteSelect.vehiculo_id
          ? this.clienteSelect.vehiculo_id
          : null,
        fact_indicador: this.fact_indicador ? 1 : 0,
        proforma_id: this.proforma_id,
        sucursal_id: this.sucursal_id,
      };

      if (this.medico_referencia == true) {
        datos = { ...datos, medico_id: this.medicoSelected };
      }

      let texto_mensaje_confirmacion = this.proforma
        ? "¿ESTÁS SEGURO DE GUARDAR LA PROFORMA?"
        : this.facturar
        ? "¿ESTÁS SEGURO DE GUARDAR LA FACTURA?"
        : "¿ESTÁS SEGURO DE GUARDAR LA VENTA?";

      //fecha_venta: this.fecha_venta,

      if (this.productosVentas.length > 0) {
        this.$confirm.require({
          header: "CONFIRMACIÓN",
          message: texto_mensaje_confirmacion,
          icon: "pi pi-exclamation-triangle",
          acceptLabel: "REGISTRAR",
          rejectLabel: "CANCELAR",
          acceptClass: "p-button-primary p-button-lg",
          rejectClass: "p-button-danger p-button-lg",
          acceptIcon: "pi pi-save",
          rejectIcon: "pi pi-times",
          closable: false,
          closeOnEscape: false,
          accept: () => {
            this.guardando = true;
            this.ventaService.crear(datos).then((res) => {
              try {
                if (has(res, "proforma")) {
                  this.$toast.add({
                    severity: "success",
                    summary: "Venta",
                    detail: "Proforma Realizada con Exito",
                    life: 3000,
                  });
                  this.guardando = false;
                  this.$router.push({
                    name: "proforma_detalle",
                    params: { id: res.proforma.id },
                  });
                  return;
                }
                if (res.status == 400) {
                  this.guardando = false;
                  this.$toast.add({
                    severity: "error",
                    summary: "VENTA",
                    detail: res.mensaje,
                    life: 60000,
                  });
                  return;
                } else {
                  this.guardando = true;
                  this.$toast.add({
                    severity: "success",
                    summary: "VENTA",
                    detail: res.mensaje,
                    life: 3000,
                  });
                  this.$router.push({
                    name: "venta_detalle",
                    params: { id: res.venta.id },
                  });
                  //this.ventaService.imprimirVenta(res.venta.id);
                }
              } catch (error) {
                this.guardando = false;
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: "Error al guardar la venta",
                  life: 60000,
                });
              }
            });
          },
          reject: () => {
            this.$toast.add({
              severity: "info",
              summary: "Venta",
              detail: "Venta Cancelada",
              life: 3000,
            });
            this.guardando = false;
          },
        });
      }
    },
    activarCliente() {
      this.clienteModal = true;
      this.cliente = {
        estado: { label: "Activo", value: 1 },
        nombre: this.clienteSelect.nombre ?? this.clienteSelect,
      };
    },
    activarClienteFactura() {
      this.clienteFacturaModal = true;
      this.cliente_factura = {
        estado: { label: "Activo", value: 1 },
        ci_nit: this.nit ? this.nit : null,
      };
    },
    cerrarModalClienteFactura() {
      this.clienteFacturaModal = false;
      this.cliente_factura = {};
    },
    activarMedico() {
      this.medicoModal = true;
      this.medico = {
        estado: { label: "Activo", value: 1 },
      };
    },
    cerraModalMedico() {
      this.medicoModal = false;
      this.medico = {};
    },
    cerrarModalCliente() {
      this.clienteModal = false;
      this.cliente = {};
    },
    guardandoCambiosCelda(e) {
      let { data, newValue, field } = e;
      data[field] = newValue;

      if (field == "cantidad_venta") {
        if (data.cantidad_venta > data.cantidad) {
          /* data.cantidad_venta = parseInt(data.cantidad == 0); */
          //hago que mi cantidad se ponga en null
          data.cantidad_venta = null;
          this.$toast.add({
            severity: "error",
            summary: "REVISAR STOCK DE PRODUCTO",
            detail: "No hay suficiente stock: " + data.nombre_producto,
            life: 60000,
          });
        } else {
          data["total"] = data["cantidad_venta"] * data["ultimo_precio"] ?? 0;
          this.calcular_subtotal();
          if (this.aplica_descuento == "aplica_descuento") {
            this.calcular_agregar_des_total_venta_porcentaje();
          }
        }
      }

      if (field == "ultimo_precio" && data["cantidad_venta"] > 0) {
        data["ultimo_precio"] = data["ultimo_precio"] ?? 0;
        data["total"] = data["cantidad_venta"] * data["ultimo_precio"];
        this.calcular_subtotal();
        if (this.aplica_descuento == "aplica_descuento") {
          this.calcular_agregar_des_total_venta_porcentaje();
        }
      }

      if (field == "descuento_neto" && data["cantidad_venta"] > 0) {
        if (newValue > data["total"]) {
          data["descuento_neto"] = data["total"];
          data["descuento_porcentaje"] =
            (data["descuento_neto"] * 100) / data["total"] /* .toFixed(2) */;
          this.calcular_descuentos_totales_venta();
          this.$toast.add({
            severity: "error",
            summary: "REVISAR DESCUENTO",
            detail: "El descuento no puede ser mayor al total",
            life: 60000,
          });
          return;
        }
        data["descuento_porcentaje"] =
          (newValue * 100) / data["total"] /* .toFixed(2) */;
        this.calcular_descuentos_totales_venta();
      }
      if (field == "descuento_porcentaje" && data["cantidad_venta"] > 0) {
        data["descuento_neto"] = (newValue * data["total"]) / 100;
        this.calcular_descuentos_totales_venta();
      }
    },
    calcular_descuentos_totales_venta() {
      let desc_venta_neto = 0;
      /* let desc_venta_porcentaje = 0; */
      this.productosVentas.forEach((producto) => {
        desc_venta_neto += producto.descuento_neto;
        /* desc_venta_porcentaje += producto.descuento_porcentaje; */
      });
      this.descuento = desc_venta_neto;
      /* this.desc_venta_porcentaje = desc_venta_porcentaje; */
      this.desc_venta_porcentaje = (this.descuento * 100) / this.subtotal;
      this.calcular_total_venta();
    },
    calcular_subtotal() {
      this.subtotal = 0;
      this.productosVentas.forEach((product) => {
        this.subtotal += product.total;
      });
      this.total_venta = this.subtotal - this.descuento;
      if (this.descuento >= 0) {
        this.agregar_descuento_detalle_productosventas();
      }
      this.calcular_interes_venta_a_credito();
    },
    agregar_descuento_detalle_productosventas() {
      this.productosVentas.forEach((producto) => {
        producto.descuento_neto =
          (this.descuento * producto.total) / this.subtotal;

        producto.descuento_porcentaje =
          (producto.descuento_neto * 100) / producto.total;
      });
    },
    calcular_total_venta() {
      this.total_venta = this.subtotal - this.descuento;
      this.calcular_interes_venta_a_credito();
    },
    calcular_interes_venta_a_credito() {
      if (this.venta_credito) {
        this.venta_credito_saldo_debe =
          this.total_venta - this.a_cuenta - this.monto_pago;
        this.monto_venta_a_credito_interes =
          this.venta_credito_saldo_debe * (this.interes_venta_a_credito / 100);
      }
    },
    calcular_descuento_venta_porcentaje() {
      this.desc_venta_porcentaje =
        (this.descuento * 100) / this.subtotal /* .toFixed(2) */;
    },
    calcular_descuento_venta_neto() {
      this.descuento =
        /* Math.round( */
        (this.desc_venta_porcentaje * this.subtotal) / 100;
      /* ); */
    },
    quitarProductoDetalle(datos) {
      this.$confirm.require({
        header: "Confirmación",
        icon: "pi pi-exclamation-triangle",
        message: "¿ESTÁS SEGURO DE ELIMINAR EL PRODUCTO?",
        acceptLabel: "Si, Eliminar",
        rejectLabel: "No, Cancelar",
        acceptClass: "p-button-primary p-button-lg",
        rejectClass: "p-button-danger p-button-lg",
        acceptIcon: "pi pi-save",
        rejectIcon: "pi pi-times",
        accept: () => {
          this.productosVentas.splice(datos.index, 1);

          if (this.productosVentas.length == 0 && this.venta_credito) {
            this.venta_credito = false;
          }

          this.calcular_subtotal();
          this.calcular_agregar_des_total_venta_porcentaje();
        },
      });
    },
    buscarCodProducto(event) {
      setTimeout(() => {
        this.productService
          .buscarXCodProducto({ texto: event.query })
          .then((response) => {
            this.codProdListadoFiltrado = [...response.productos];
          })
          .catch((error) => console.log(error));
      }, 250);
    },
    buscarProducto(event) {
      setTimeout(() => {
        let _text = event.query ?? event.value;
        this.productService
          .buscarXNombreVenta({
            texto: _text,
            buscarProductoPorSelected: this.buscarProductoPorSelected,
            grupo_id: this.grupoSelected,
            laboratorio_id: this.laboratorioSelected,
            sucursal_id: this.sucursal_id,
          })
          .then((response) => {
            if (response.productos.length == 0) {
              this.$toast.add({
                severity: "info",
                summary: "Producto",
                detail: "No se encontraron productos",
                life: 2000,
              });
            }
            this.productosListadoFiltrado = [...response.productos];
          })
          .catch((error) => console.log(error));
      }, 200);
    },
    cargarClientesListado(selected = null) {
      this.clienteService
        .getClientesAll()
        .then((response) => {
          if (selected) {
            this.clienteSelect = selected;
            this.clientes = [];
          } else {
            this.clientes = response;
          }
        })

        .catch((error) => console.log(error));
    },
    cargarVehiculos(vehiculo = null) {
      if (vehiculo) {
        let datos = {
          cliente_id: vehiculo.cliente_id,
          nombre: vehiculo.nombre_cliente,
          ci_nit: vehiculo.ci_nit,
          placa: vehiculo.placa,
          marca: vehiculo.marca_texto,
          modelo: vehiculo.modelo_texto,
          vehiculo_id: vehiculo.id,
        };
        this.clienteSelect = datos;
        this.ci_nit = vehiculo.ci_nit;
        this.nombre_cliente = vehiculo.nombre_cliente;
      }
    },
    cargarMedicosListado(medico = null) {
      this.medicoService.getMedicosAll().then((response) => {
        this.medicos = response.medicos;
        if (medico) {
          this.medicos.forEach((element) => {
            if (element.id == medico.id) {
              this.medicoSelected = element.id;
            }
          });
        }
      });
    },
    agregarDetalleVenta(data) {
      if (this.productosVentas.length > 0) {
        let existe = this.productosVentas.find(
          (producto) => producto.id == data.id
        );
        if (existe) {
          this.$toast.add({
            severity: "info",
            summary: "Venta",
            detail: "El producto ya se encuentra en la venta",
            life: 60000,
          });
          return;
        }
      }
      if (data.ultimo_precio == 0 || data.precio_sugerido == 0) {
        this.$toast.add({
          severity: "info",
          summary: "Venta",
          detail:
            "El producto no tiene un precio asignado: " + data.nombre_producto,
          life: 60000,
        });
        return;
      }
      if (data.ultimo_precio_compra == 0 && data.tipo_catalogo == 1) {
        this.$toast.add({
          severity: "info",
          summary: "Venta",
          detail:
            "El producto no tiene un precio de compra asignado: " +
            data.nombre_producto,
          life: 60000,
        });
        return;
      }

      let precio =
        data.ultimo_precio == 0 ? data.precio_sugerido : data.ultimo_precio;
      this.productosVentas.push({
        cantidad_venta: null,
        descuento_neto: 0,
        descuento_porcentaje: 0,
        ...data,
        ultimo_precio: precio,
        total: precio * data.cantidad_venta,
      });
      // verifico si el producto no tiene stock para no agregarlo al detalle
      if (
        data.cantidad == 0 ||
        (data.cantidad < data.cantidad_venta && data.tipo_catalogo == 1)
      ) {
        this.$toast.add({
          severity: "info",
          summary: "Venta",
          detail: "El producto: " + data.nombre_producto + " no tiene stock",
          life: 60000,
        });
        // hay que quitar el producto agregado al detalle
        this.productosVentas.pop();
        this.productoSelecionado = null;
        return;
      }

      this.stockSucursales = [];
      this.productosListadoFiltrado = [];
      this.productoSelecionado = null;

      this.calcular_subtotal();
    },
    agregarProductos(productosSeleccionados) {
      productosSeleccionados.forEach((producto) => {
        try {
          if (
            producto.stock == null ||
            producto.stock == "" ||
            producto.stock == 0
          ) {
            this.$toast.add({
              severity: "info",
              summary: "VENTA/STOCK",
              detail:
                "El producto: " + producto.descripcion + " no tiene stock",
              life: 60000,
            });
            return;
          }
          this.agregarDetalleVenta({
            ...producto,
            cantidad: producto.stock[0].cantidad,
            producto_id: producto.id,
            sucursal_id: producto.stock[0].sucursal_id,
            nombre_producto: producto.descripcion,
            cantidad_venta: 1,
            descuento_neto: 0,
            descuento_porcentaje: 0,
            ultimo_precio: parseFloat(producto.precio_sugerido),
            precio_sugerido: parseFloat(producto.precio_sugerido),
            total: parseFloat(producto.precio_sugerido * 1),
          });
          this.calcular_subtotal();
        } catch (error) {
          console.log(error);
        }
      });

      this.cerrarModalProductoResultados();
    },
    agregarProductosPaqueteAlDetalle() {
      let datos = {
        paquete_producto_id: this.paqueteSelected,
      };
      this.paqueteProductoService
        .getPaqueteProductoById(datos)
        .then((response) => {
          /* this.paqueteSelected = null; */
          response.paqueteproductosListado.forEach((producto) => {
            let paquete_cantidad = producto.cantidad;
            let precio =
              producto.stock_para_caja_activa == null
                ? producto.producto.precio_sugerido
                : producto.stock_para_caja_activa.ultimo_precio;
            //comparo la cantidad del stock con la cantidad del paquete
            if (producto.stock_para_caja_activa.cantidad < paquete_cantidad) {
              this.$toast.add({
                severity: "error",
                summary: "Revisar stock del producto",
                detail:
                  "El producto no tiene stock suficiente: " +
                  producto.producto.descripcion,
                life: 60000,
              });
              return;
            }
            this.agregarDetalleVenta({
              ...producto,
              id: producto.producto_id,
              cantidad: producto.stock_actual,
              sucursal_id: producto.sucursal_id,
              cantidad_venta: paquete_cantidad,
              descuento_neto: 0,
              descuento_porcentaje: 0,
              ultimo_precio: parseFloat(precio),
              precio_sugerido: parseFloat(producto.producto.precio_sugerido),
              total: parseFloat(precio * producto.cantidad),
            });
            /* this.productosVentas.push(); */

            this.calcular_subtotal();
          });
        })
        .catch((error) => console.log(error));
    },
    agregarProductosProforma() {
      this.ventaService
        .buscarProforma(this.proforma_codigo)
        .then((response) => {
          if (response.proforma != null) {
            this.proforma_id = response.proforma.id;
            let datos_cliente = {
              nit: response.proforma.ci_nit,
              razon_social: response.proforma.cliente,
              cliente_id: response.proforma.cliente_id,
              nombre: response.proforma.cliente,
              id: response.proforma.cliente_id,
            };
            this.clienteSelect = datos_cliente;
            response.proforma.detalles.forEach((producto) => {
              let cantidad_proforma = producto.pivot.cantidad;
              let precio =
                producto.ultimo_precio == 0 ||
                producto.ultimo_precio == undefined
                  ? producto.precio_sugerido
                  : producto.ultimo_precio;
              this.agregarDetalleVenta({
                ...producto,
                id: producto.pivot.producto_id,
                producto_id: producto.pivot.producto_id,
                nombre_producto: producto.descripcion,
                cantidad: producto.stock_actual,
                cantidad_venta: producto.pivot.cantidad,
                descuento_neto: 0,
                descuento_porcentaje: 0,
                ultimo_precio: parseFloat(precio),
                precio_sugerido: parseFloat(producto.precio_sugerido),
                total: parseFloat(precio * cantidad_proforma),
                lote: producto.pivot.lote ?? null,
                fecha_vence: producto.pivot.fecha_vence ?? null,
              });
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Proforma",
              detail: "No se encontro la proforma",
              life: 60000,
            });
          }
        });
    },
  },
  watch: {
    interes_venta_a_credito() {
      this.monto_venta_a_credito_interes =
        this.venta_credito_saldo_debe * (this.interes_venta_a_credito / 100);
    },
    venta_credito() {
      if (this.venta_credito == true) {
        this.tipo_pagoSelect = 2;
        this.metodo_pagoSelect = 2;
        this.a_cuenta = 0;
        this.monto_pago = 0;
        this.dias_credito = 0;
        this.efectivo = 0;
        this.venta_credito_saldo_debe = this.total_venta;
      } else {
        this.tipo_pagoSelect = 1;
        this.metodo_pagoSelect = 1;
        this.a_cuenta = 0;
        this.monto_pago = 0;
        this.dias_credito = 0;
        this.efectivo = this.total_venta;
        this.venta_credito_saldo_debe = 0;
      }
    },
    clienteSelect(datos) {
      if (this.facturar == true) {
        this.grupos_id_selected =
          datos.grupos_id_selected != null
            ? datos.grupos_id_selected.join(",\n")
            : null;
        // si es factura
      } else {
        this.nit = datos.ci_nit;
        this.razon_social = datos.nombre;
        this.grupos_id_selected =
          datos.grupos_id_selected != null
            ? datos.grupos_id_selected.join(",\n")
            : null;
      }
    },

    productoSelecionado(newVal) {
      if (newVal != null) {
        if (newVal.id) {
          //this.productosVentas.push(newVal);
          this.stockSucursales = [];
          newVal.stock.forEach((sucursal) => {
            let precio_sugerido =
              sucursal.ultimo_precio == 0
                ? newVal.precio_sugerido
                : sucursal.ultimo_precio;
            this.stockSucursales.push({
              ...sucursal,
              ultimo_precio: parseFloat(precio_sugerido),
              cantidad_venta: 1,
              descuento_neto: 0,
              descuento_porcentaje: 0,
            });
            // automaticamente selecciono la sucursal con el menor id y llamo a mi funcion agregarDetalleVenta
            if (sucursal.id == this.stockSucursales[0].id) {
              this.agregarDetalleVenta(this.stockSucursales[0]);
            }
          });
        }
      }
    },
    codProdSelected(newVal) {
      if (newVal != null) {
        if (newVal.id) {
          //this.productosVentas.push(newVal);
          this.stockSucursales = [];
          newVal.stock.forEach((sucursal) => {
            let precio_sugerido =
              sucursal.ultimo_precio == 0
                ? newVal.precio_sugerido
                : sucursal.ultimo_precio;
            this.stockSucursales.push({
              ...sucursal,
              ultimo_precio: parseFloat(precio_sugerido),
              cantidad_venta: 0,
              descuento_neto: 0,
              descuento_porcentaje: 0,
            });
            // automaticamente selecciono la sucursal con el menor id y llamo a mi funcion agregarDetalleVenta
            if (sucursal.id == this.stockSucursales[0].id) {
              this.agregarDetalleVenta(this.stockSucursales[0]);
            }
            this.codProdSelected = null;
          });
        }
      }
    },
    /*  descuento() {
      this.calcular_total_venta();
      this.calcular_descuento_venta_porcentaje();
      this.agregar_descuento_detalle_productosventas();
    },
    desc_venta_porcentaje() {
      this.calcular_descuento_venta_neto();
    }, */
    total_venta() {
      if (this.venta_credito) {
        this.venta_credito_saldo_debe = this.total_venta - this.a_cuenta;
      }
      if (this.tipo_pagoSelect == 1) {
        this.efectivo = this.total_venta;
      } else {
        this.a_cuenta = this.a_cuenta > 0 ? this.a_cuenta : 0;
        this.efectivo = 0;
      }
      if (this.metodo_pagoSelect == 1) {
        this.monto_pago = 0;
      } else {
        this.monto_pago = this.total_venta;
      }
    },
    a_cuenta() {
      if (this.a_cuenta > this.total_venta) {
        this.$toast.add({
          severity: "info",
          summary: "Venta",
          detail: "El monto a cuenta no puede ser mayor al total de la venta",
          life: 60000,
        });
        this.a_cuenta = 0;
        return;
      }
      if (this.venta_credito) {
        this.venta_credito_saldo_debe = this.total_venta - this.a_cuenta;
        this.calcular_interes_venta_a_credito();
        return;
      }
      this.efectivo = this.a_cuenta;
    },
    monto_pago() {
      if (this.venta_credito) {
        this.venta_credito_saldo_debe = this.total_venta - this.monto_pago;
        this.calcular_interes_venta_a_credito();
        return;
      }

      if (this.tipo_pagoSelect == 2) {
        this.efectivo = this.a_cuenta;
      } else {
        this.efectivo = this.total_venta - this.monto_pago;
      }
    },
    tipo_pagoSelect() {
      if (this.tipo_pagoSelect == 1) {
        this.a_cuenta = 0;
        this.monto_pago = 0;
        this.dias_credito = 0;
        this.efectivo = this.total_venta;
        this.a_credito_check = "";
      } else {
        this.dias_credito = 30;
        this.interes_venta_a_credito = 0;
        this.a_cuenta = 0;
        this.metodo_pagoSelect = 1;
        this.monto_pago = 0;
        this.efectivo = this.a_cuenta;
      }
    },
    metodo_pagoSelect() {
      if (this.metodo_pagoSelect == 1) {
        this.monto_pago = 0;
        this.efectivo = this.total_venta - this.a_cuenta;
        this.metodo_pago_check = "";
        this.nro_tarjeta = null;
      } else if (
        this.metodo_pagoSelect == 2 &&
        this.a_credito_check == "a_credito_check"
      ) {
        this.monto_pago = 0;
        this.efectivo = 0;
      } else {
        this.efectivo = 0;
        this.monto_pago = this.total_venta - this.efectivo;
      }
    },
    metodo_pago_check() {
      if (this.metodo_pago_check == "metodo_pago_check") {
        this.metodo_pagoSelect = 6;
        this.efectivo = 0;
        this.monto_pago = this.total_venta - this.efectivo;
      } else if (
        this.metodo_pago_check == "metodo_pago_check" &&
        this.a_credito_check == "a_credito_check"
      ) {
        this.efectivo = 0;
        this.monto_pago = 0;
      } else {
        this.metodo_pagoSelect = 1;
        this.metodo_pago_check = "";
        this.monto_pago = 0;
        this.efectivo = this.total_venta - this.a_cuenta;
      }
    },
    a_credito_check() {
      if (this.a_credito_check == "a_credito_check") {
        this.tipo_pagoSelect = 2;
        this.dias_credito = 30;
        this.a_cuenta = 0;
        this.metodo_pagoSelect = 1;
        this.monto_pago = 0;
        this.efectivo = this.a_cuenta;
      } else {
        this.tipo_pagoSelect = 1;
        this.a_credito_check = "";
        this.a_cuenta = 0;
        this.dias_credito = 0;
        this.efectivo = this.total_venta;
      }
    },
    aplica_descuento() {
      if (this.aplica_descuento == "aplica_descuento") {
        this.desc_venta_porcentaje = 0;
        this.calcular_agregar_des_total_venta_porcentaje();
        /* this.calcular_descuento_venta_porcentaje();
        this.agregar_descuento_detalle_productosventas(); */
        /* Math.round(this.descuento * 100) / 100; */
      } else {
        this.desc_venta_porcentaje = 0;
      }
    },
    facturar() {
      this.nit = "";
      this.razon_social = "";
    },
  },
};
</script>
<style lang="scss" scoped>
.p-invalid {
  color: red;
}

::v-deep(.editable-cells-table td.p-cell-editing) {
  padding-top: 0;
  padding-bottom: 0;
}

.outofstock {
  font-weight: 700;
  color: #000000;
  background-color: #ff5252;
  padding: 0 0.5em;
  border-radius: 0.5em;
}

.lowstock {
  font-weight: 700;
  color: #000000;
  background-color: #fbc02d;
  padding: 0 0.5em;
  border-radius: 0.5em;
}

.instock {
  font-weight: 700;
  color: #000000;
  background-color: #7bffae;
  padding: 0 0.5em;
  border-radius: 0.5em;
}
.p-fondo {
  background-color: #7bffae;
  border-radius: 0.5em;
  border: 1px solid #002236;
}
.precio {
  font-weight: 700;
  color: #000000;
  background-color: #7bffae;
  padding: 0 0.5em;
  border-radius: 0.5em;
}
.p-inputgroup-addon {
  background-color: #ffffff;
  font-weight: bold;
  color: #000000;
  border: none;
}
</style>
